<template>
  <div
    class="v-data-footer"
  >
    <div class="v-data-footer__select">
      Rows per page:
      <v-combobox
        attach=".v-data-footer"
        :menu-props="{top:true}"
        :value="pagination.itemsPerPage"
        dense
        type="number"
        hide-details
        class="ml-2"
        :items="itemsPerPageOptions"
        @input="(itemsPerPage) => {updateOptions({itemsPerPage})
                                   setPagesInStorage(itemsPerPage)
        }"
      />
      <slot></slot>
    </div>
    <div class="v-data-footer__pagination">
      {{ pagination.pageStart+1 }}-{{ pagination.pageStop }} of {{ pagination.itemsLength }}
    </div>
    <v-btn
      :disabled="isFirstPage"
      icon
      text
      @click="() => updateOptions({page: 1})"
    >
      <v-icon>{{ icons.firstIcon }}</v-icon>
    </v-btn>
    <v-btn
      :disabled="isFirstPage"
      icon
      text
      @click="() => updateOptions({page: pagination.page - 1})"
    >
      <v-icon>{{ icons.prevIcon }}</v-icon>
    </v-btn>
    <span>{{ pagination.page }}</span>
    <v-btn
      icon
      :disabled="isLastPage"
      text
      @click="() => updateOptions({page: pagination.page + 1})"
    >
      <v-icon>{{ icons.nextIcon }}</v-icon>
    </v-btn>
    <v-btn
      icon
      :disabled="isLastPage"
      text
      @click="() => updateOptions({page: pagination.pageCount})"
    >
      <v-icon>{{ icons.lastIcon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>

import {
  mdiPageLast, mdiPageFirst, mdiChevronLeft, mdiChevronRight,
} from '@mdi/js'

export default {
  props: {
    pagination: { type: Object, default: () => {} },
    itemsPerPageOptions: { type: Array, default: () => [] },
  },

  data() {
    return {
      icons: {
        prevIcon: mdiChevronLeft,
        nextIcon: mdiChevronRight,
        firstIcon: mdiPageFirst,
        lastIcon: mdiPageLast,
      },
    }
  },

  computed: {
    isFirstPage() {
      return this.pagination.page - 1 <= 0
    },
    isLastPage() {
      return this.pagination.page + 1 > this.pagination.pageCount
    },
  },

  methods: {
    updateOptions(options = {}) {
      this.$emit('update:options', { ...this.options, ...options })
    },
    setPagesInStorage(count) {
      localStorage.setItem('itemsPerPage', count)
    },
  },
}
</script>

<style scoped>
  .v-data-footer__select{
    justify-content: flex-start;
  }

  ::v-deep > .v-data-footer__select .v-input{
    min-width: 75px;
  }
</style>
